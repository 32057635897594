<template>
  <div class="GanNews2">
    <div class="img">
    <div class="text">
      <h3>又"稻"双抢时</h3>
      <p>盛夏七月，骄阳似火，满载希望。眼下正值“双抢”时节，全省各地早稻收割工作正在紧锣密鼓地进行中。我们共同见证一股创新农业服务模式、助力农户节本增效的新生力量—— “赣供农服”（江西供销农业综合云服务平台）上线！</p>
      <p>它代表着省、市、县三级供销联社在农业社会化服务领域的创新和进步，是积极推进农业农村现代化、信息化的重要举措。我们诚挚邀请广大农户、农机手、社有企业和社会各界加入我们的平台，体验高效、便捷、智能的农业社会化服务。同时，我们也期待与社会各界朋友携手合作，牢牢守住粮食安全，实现农业丰收、农村繁荣、农民富裕的奋斗目标。</p>
    </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.GanNews2 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.img{
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.text{
  margin: 0 auto;
  margin-top: 3.7037rem;
  width: 12.963rem;
  text-align: center;
}

p{
  text-indent: 2em;
  line-height: .463rem;
  margin: .1852rem 0rem;
}
</style>