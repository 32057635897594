<template>
  <div class="GanNews">
    <div class="img"></div>
    <div class="news">
      <h2>新闻动态</h2>
      <ul v-for="(page, index) in paginatedNewsList" :key="index" :class="{'mu-page-detail': true, hide: index !== currentPage}">
        <li v-for="item in page" :key="item.id">
          <router-link :to="item.url">{{ item.title }}</router-link>
          <span>{{ item.date }}</span>
        </li>
      </ul>
    </div>
    <div class="mupage">
      <span
        v-for="(page, index) in paginatedNewsList"
        :key="index"
        :class="['mu-page-num', { active: index === currentPage }]"
        @click="goToPage(index)"
      >
        {{ index + 1 }}
      </span>
    </div>
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  data() {
    return {
      newsList: [
        { id: 5, title: '"组织处理"和"纪律处分"有什么区别?', date: '2024-7-31', url: "/news/news1" },
        { id: 6, title: '又"稻"双抢时', date: '2024-7-29', url: "/news/news2" },
        { id: 7, title: '《南昌市市级化肥淡季储备管理办法》政策解读', date: '2024-7-27', url: "/news/news3" },
        { id: 8, title: '关于《2024年南昌市供销合作社联合社社有企业公开招聘公告》的补充公告', date: '2024-7-25', url: "/news/news4" },
        { id: 9, title: '关于《南昌市供销合作社联合社社有企业公开招引经营管理人才公告》的补充公告', date: '2024-7-25', url: "/news/news1" },
        { id: 10, title: '招租公告:六个点房产公开招租', date: '2024-7-25', url: "/news/news1" },
        { id: 11, title: '公文处理常见错情提示———全市党委法规和公文处理工作培训班课件之一', date: '2024-7-25', url: "/news/news1" },
        { id: 12, title: '招租公告|六处优质店铺公开招租', date: '2024-7-24', url: "/news/news1" },
        { id: 13, title: '【党务知识周周学】纪律处分条例|对阳奉阴违的"两面人"行为如何追究党纪责任', date: '2024-7-24', url: "/news/news1" },
        { id: 14, title: '供销管理公司组织讨论2024年度考核实施方案', date: '2024-7-24', url: "/news/news1" },
        { id: 51, title: '占位字符占位字符占位字符占位字符占位字符1', date: '2024-8-01', url: "/news/news1" },
        { id: 52, title: '占位字符占位字符占位字符占位字符占位字符2', date: '2024-8-01', url: "/news/news1" },
        { id: 53, title: '占位字符占位字符占位字符占位字符占位字符3', date: '2024-8-01', url: "/news/news1" },
        { id: 54, title: '占位字符占位字符占位字符占位字符占位字符4', date: '2024-8-01', url: "/news/news1" },
        { id: 55, title: '占位字符占位字符占位字符占位字符占位字符5', date: '2024-8-01', url: "/news/news1" },
        { id: 56, title: '占位字符占位字符占位字符占位字符占位字符6', date: '2024-8-01', url: "/news/news1" },
        { id: 57, title: '占位字符占位字符占位字符占位字符占位字符7', date: '2024-8-01', url: "/news/news1" },
        { id: 58, title: '占位字符占位字符占位字符占位字符占位字符8', date: '2024-8-01', url: "/news/news1" },
        { id: 59, title: '占位字符占位字符占位字符占位字符占位字符9', date: '2024-8-01', url: "/news/news1" },
        { id: 60, title: '占位字符占位字符占位字符占位字符占位字符10', date: '2024-8-01', url: "/news/news1" },
        { id: 61, title: '占位字符占位字符占位字符占位字符占位字符11', date: '2024-8-01', url: "/news/news1" },
        { id: 62, title: '占位字符占位字符占位字符占位字符占位字符12', date: '2024-8-01', url: "/news/news1" },
        { id: 63, title: '占位字符占位字符占位字符占位字符占位字符13', date: '2024-8-01', url: "/news/news1" },
        { id: 64, title: '占位字符占位字符占位字符占位字符占位字符14', date: '2024-8-01', url: "/news/news1" },
        { id: 65, title: '占位字符占位字符占位字符占位字符占位字符15', date: '2024-8-01', url: "/news/news1" },
        { id: 66, title: '占位字符占位字符占位字符占位字符占位字符16', date: '2024-8-01', url: "/news/news1" },
        { id: 67, title: '占位字符占位字符占位字符占位字符占位字符17', date: '2024-8-01', url: "/news/news1" },
        { id: 68, title: '占位字符占位字符占位字符占位字符占位字符18', date: '2024-8-01', url: "/news/news1" },
        { id: 69, title: '占位字符占位字符占位字符占位字符占位字符19', date: '2024-8-01', url: "/news/news1" },
        { id: 70, title: '占位字符占位字符占位字符占位字符占位字符20', date: '2024-8-01', url: "/news/news1" },
      ],
      currentPage: 0,
      itemsPerPage: 10,
    };
  },
  computed: {
    paginatedNewsList() {
      return this.newsList.reduce((acc, item, index) => {
        const pageIndex = Math.floor(index / this.itemsPerPage);
        if (!acc[pageIndex]) acc[pageIndex] = [];
        acc[pageIndex].push(item);
        return acc;
      }, []);
    },
  },
  methods: {
    goToPage(pageIndex) {
      this.currentPage = pageIndex;
    }
  }
};
</script>

<style scoped>
.GanNews {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.img {
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.hide {
  display: none;
}

.news {
  margin-top: 2.7778rem;
}

h2,
ul {
  margin-left: .6481rem;
}

li {
  border-bottom: #c0c0c0 .0185rem solid;
  margin: .463rem 0;
  padding-bottom: .2778rem;
  width: 90%;
  display: flex;
  justify-content: space-between;
  list-style: square;
}

a {
  color: blue;
}

.mupage {
  text-align: center;
}

.mu-page-num {
  cursor: pointer;
  margin: 0 .0926rem;
  padding: .0926rem .1852rem;
  border: .0185rem solid #ccc;
  border-radius: .0741rem;
}

.mu-page-num.active {
  background-color: #00bfff;
  color: #fff;
  border-color: #00bfff;
}
</style>
