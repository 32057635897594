<template>
  <div class="GanContact">
    <div class="img"></div>
    <div class="text">
      <h2>联系我们</h2>
      <p>江西省南昌市红谷滩区九龙湖婺源路1020号南昌供销大楼商务商业楼20楼2013室。</p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.GanContact{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.img{
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.text{
  margin-top: 2.7778rem;
  text-align: center;
}

h2{
  background-color: #DCDCDC;
  height: 1.2222rem;
  line-height: 1.2222rem;
}
</style>