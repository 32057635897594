<template>
  <footer class="GanFooter">
    <p>所有内容仅供测试 暂无商业用途 侵权立删 如有侵权联系fanxiwenzheng@gmail.com 备注侵权内容</p>
  </footer>
</template>

<script>
export default {

}
</script>

<style scoped>
</style>