import GanHome from '@/views/GanHome'
import GanBrief from '@/views/GanBrief'
import GanNews from '@/views/GanNews'
import GanNews1 from '@/views/GanNews1'
import GanNews2 from '@/views/GanNews2'
import GanNews3 from '@/views/GanNews3'
import GanNews4 from '@/views/GanNews4'
import GanFarming from '@/views/GanFarming'
import GanContact from '@/views/GanContact'
import GanService from '@/views/GanService'
import GanKnowLedge from '@/views/GanKnowLedge'
import GanProduct from '@/views/GanProduct'

import Vue from 'vue'
import VueRouter from 'vue-router'

// VueRouter 初始化
Vue.use(VueRouter)

// 创建路由对象
const router = new VueRouter({
  routes: [
    { path: '/', redirect: '/home' },
    { path: '/home', component: GanHome },
    { path: '/brief', component: GanBrief },
    { path: '/news', component: GanNews },
    { path: '/news/news1', component: GanNews1 },
    { path: '/news/news2', component: GanNews2 },
    { path: '/news/news3', component: GanNews3 },
    { path: '/news/news4', component: GanNews4 },
    {
      path: '/farming',
      component: GanFarming,
      children: [
        { path: '', redirect: 'service' },
        { path: 'service', component: GanService },
        { path: 'knowledge', component: GanKnowLedge },
        { path: 'product', component: GanProduct }
      ]
    },
    { path: '/contact', component: GanContact }
  ]
})

export default router
