<template>
  <div class="GanBrief">
    <div class="img"></div>
    <div class="text">
      <h2>公司简介</h2>
        <p>
          江西供销智慧农业服务有限公司成立于2024年1月30日，注册资本金为200万元（实缴）：
          其中南昌市供销有限公司出资90万元，占45% 股权；
          江西省金合供销农业投资有限公司出资70万元，占35 %股权；南昌县农业生产资料公司出资10万元，占5 %股权，
          江西省昌荣农资有限责任公司出资10万元，占5 %股权，江西省农泽为华互联网科技有限公司出资20万元，占10 %股权，
          五方均采用货币出资的方式。公司注册地位于江西省南昌市红谷滩区九龙湖婺源路1020号南昌供销大楼商务商业楼20楼2013室。
        </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.GanBrief {
  width: 100%;
  height: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.img{
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.text{
  margin-top: 2.4074rem;
  text-align: center;
  width: 20.3704rem;
}

h2{
  background-color: #DCDCDC;
  height: 1.2222rem;
  line-height: 1.2222rem;
}
</style>