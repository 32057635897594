<template>
  <div class="GanNews1">
    <div class="img"></div>
    <div class="text">
      <img src="@/assets/news1(2.webp" alt="">
      <img src="@/assets/news1.png" alt="">
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.GanNews1 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.img{
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.text{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 13.8889rem;
  margin: 0rem auto;
  margin-top: 3.7037rem;
}
</style>