<template>
  <div class="GanService">
    <h3>专家团队</h3>
    <ul>
      <li v-for="item in imgList" :key="item.id">
        <a href="#">
          <img :src="item.url" alt="">
        </a><br>
        <span>{{ item.name }}</span><br>
        <span>{{ item.title }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import url from '@/assets/sb.jpg'
export default {
data(){
  return{
    imgList:[
      {
        id:15,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:16,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:17,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:18,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:19,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:20,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:21,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:22,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:23,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:24,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:25,
        url,
        name:'占位',
        title:'占位字符'
      },
      {
        id:26,
        url,
        name:'占位',
        title:'占位字符'
      }
    ]
  }
}
}
</script>

<style scoped>
ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  gap: .3704rem;
  margin: .463rem 0;
}

li {
  margin: 0;
  list-style: none;
}

ul img {
  width: 3.7037rem;
  height: 4.0741rem;
}

h3{
  margin-top: .5556rem;
  border: .037rem solid black;
  height: .7407rem;
  line-height: .7407rem;
}
</style>