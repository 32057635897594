<template>
  <div class="GanHome">
    <div 
      v-for="(url, index) in Urls" 
      :key="index" 
      :style="{ backgroundImage: 'url(' + url + ')' }" 
      :class="{ active: index === keyIndex, hidden: index !== keyIndex }"
      class="banner-image">
    </div>
    <ol>
      <li v-for="(item, index) in bannerList" :key="item.id" :class="{ active: index === keyIndex }"></li>
    </ol>
  </div>
</template>

<script>
import url1 from "@/assets/bn1.jpg";
import url2 from "@/assets/bn2.jpg";
import url4 from "@/assets/bn4.jpg";
import url5 from "@/assets/bn5.jpg";

export default {
  data() {
    return {
      bannerList: [
        { id: 1 },
        { id: 2 },
        { id: 3 },
        { id: 4 }
      ],
      Urls: [url1, url2, url4, url5],
      keyIndex: 0,
      timerId: null
    };
  },
  mounted() {
    this.startInterval();
  },
  beforeDestroy() {
    this.stopInterval();
  },
  methods: {
    startInterval() {
      this.timerId = setInterval(() => {
        this.keyIndex = (this.keyIndex + 1) % this.Urls.length;
      }, 3000);
    },
    stopInterval() {
      if (this.timerId) {
        clearInterval(this.timerId);
        this.timerId = null;
      }
    }
  }
};
</script>

<style scoped>
.GanHome {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
}

.banner-image {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.banner-image.active {
  opacity: 1;
}

.GanHome ol {
  position: absolute;
  bottom: 1.2963rem;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
}

.GanHome li {
  width: .1852rem;
  height: .1852rem;
  border-radius: 50%;
  background-color: #fff;
  margin: 0 .0926rem;
  opacity: 0.5;
  transition: opacity 0.5s;
}

.GanHome li.active {
  opacity: 1;
}
</style>
