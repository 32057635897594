<template>
  <div class="GanFarming">
    <div class="img"></div>
    <div class="nav">
      <h2>农业社会化服务</h2>
        <div class="Flink">
          <router-link to="/farming/service">服务团队</router-link>
          <router-link to="/farming/knowledge">农技知识</router-link>
          <router-link to="/farming/product">产品信息</router-link>
        </div>
    </div>
    <div class="Ftop">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.GanFarming{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  width: 100%;
  margin-bottom: 1.2222rem;
}

.img{
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.nav{
  margin-top: 2.7778rem;
  border-bottom: .0556rem solid #DCDCDC;
  padding-bottom: .1852rem;
}

h2{
  margin-left: .7407rem;
}

a{
  margin: 0 .7407rem;
  padding-bottom: .1852rem;
}

a.router-link-active{
  border-bottom: .0556rem solid #8FBC8F;
}
</style>