<template>
  <div class="GanKnowLedge">
    <h3>农技知识</h3>
    <ul>
      <li v-for="item in UrlList" :key="item.id">
        <a href="#">
          <img :src="item.urls" alt="">
        </a><br>
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import urls from '@/assets/sb2.jpg'
export default {
data(){
  return{
    UrlList:[
      {
        id:27,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:28,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:29,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:30,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:31,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:32,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:33,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:34,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:35,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:36,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:37,
        urls,
        name:'《占位字符占位字符》'
      },
      {
        id:38,
        urls,
        name:'《占位字符占位字符》'
      }
    ]
  }
}
}
</script>

<style scoped>
ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  gap: .3704rem;
  margin: .463rem 0;
}

li {
  margin: 0;
  list-style: none;
}

ul img {
  width: 3.7037rem;
  height: 4.0741rem;
}

h3{
  margin-top: .5556rem;
  border: .037rem solid black;
  height: .7407rem;
  line-height: .7407rem;
}
</style>