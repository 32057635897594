<template>
  <div class="App">
    <div v-if="loading" class="loading-overlay">
      <p class="loading-text">页面加载中...</p>
    </div>
    <header>
      <p class="img">江西供销智慧农业服务有限公司</p>
      <div class="link">
        <router-link to="/home">首页</router-link>
        <router-link to="/brief">公司介绍</router-link>
        <router-link to="/news">新闻动态</router-link>
        <router-link to="/farming">农业社会化服务</router-link>
        <router-link to="/contact">联系我们</router-link>
      </div>
    </header>
    <div class="content_wrap">
      <div class="top">
        <router-view @hook:mounted="onContentLoaded"></router-view>
      </div>
    </div>
    <GanFooter></GanFooter>
  </div>
</template>

<script>
import GanFooter from '@/components/GanFooter.vue'

export default {
  components: {
    GanFooter
  },
  data() {
    return {
      loading: true
    }
  },
  methods: {
    onContentLoaded() {
      this.loading = false;
    }
  }
}
</script>

<style>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

a {
  text-decoration: none;
  font-size: .2963rem;
}

h2 {
  font-size: .3704rem;
  margin-top: 1.2963rem;
  margin-bottom: .3704rem;
}

h3 {
  margin-top: .5556rem;
  border: .037rem solid black;
  height: .7407rem;
  line-height: .7407rem;
}

p {
  font-size: .2778rem;
}

span {
  font-size: .2593rem;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

header {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #f1f1f1;
  border-bottom: .0556rem solid #8FBC8F;
  display: flex;
  padding: .1852rem;
  z-index: 1000;
  justify-content: space-between;
}

.link {
  width: 8.3333rem;
  display: flex;
  justify-content: space-around;
}

.link a {
  align-self: center;
}

.content_wrap {
  display: flex;
  flex: 1;
  width: 100%;
}

.top {
  display: flex;
  flex: 1;
  overflow-y: auto;
}

.GanFooter {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f1f1f1;
  padding: .1852rem;
  text-align: center;
  border-top: .0185rem solid #ccc;
  z-index: 1000;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1001;
}

.loading-text {
  font-size: .4444rem;
  color: #000;
}
</style>
