<template>
  <div class="GanNews3">
    <div class="img"></div>
    <div class="content">
      <h3>《南昌市市级化肥淡季储备管理办法》政策解读</h3>
      <h4>一、制定背景</h4>
      <p>化肥是粮食的“粮食”，对粮食增产贡献率在40%以上。为保障春耕化肥供应和价格基本稳定以及灾后应急用肥等需要，江西省发展改革委江西省财政厅于2021年1月23日印发了《江西省省级化肥商业储备管理办法》（赣发改经贸规〔2021〕72号）。按照市政府的部署，市供销联社、市发展改革委、市财政局，依据赣发改经贸规〔2021〕72号文件和原南昌市人民政府办公厅印发的《南昌市市级化肥淡季储备管理办法》（洪府厅发〔2016〕111号），结合实际，研究制定本管理办法（以下简称《办法》），着力保障全市农业生产用肥和化肥市场的供应稳定。</p>

      <h4>二、《办法》主要内容</h4>
      <p>《办法》包括总则、储备规模及时间、承储企业基本条件及选定方式、储备任务下达及管理、储备任务履行及考核、储备财务管理、储备监督管理、附则等8章26条内容。主要内容是：</p>
      <ul>
        <li>
          <h5>第一章：总则</h5>
          <p>共包含5个条款，明确了化肥淡季储备的目的、适用范围、基本原则、指定管理单位职责分工，由市供销联社、市发展改革委、市财政局共同做好化肥淡季储备管理工作。</p>
        </li>
        <li>
          <h5>第二章：储备规模及时间</h5>
          <p>共包含3个条款，明确化肥淡季储备工作任务计划确定的程序；化肥淡季储备总量为6万吨，以尿素和高浓度复合肥为主，其中尿素比例不低于20%，储备期限为当年10月1日至次年3月31日。</p>
        </li>
        <li>
          <h5>第三章：承储企业基本条件及选定方式</h5>
          <p>共包含4个条款，从企业经营实力、银行信誉等方面对承储企业明确了要求；依法通过公开招标、邀请招标等方式确定化肥淡季储备承储企业。</p>
        </li>
        <li>
          <h5>第四章：储备任务下达及管理</h5>
          <p>共包含2个条款，根据中标结果下达储备任务；承储仓库应标注“南昌市市级化肥淡季储备库”。</p>
        </li>
        <li>
          <h5>第五章：储备任务履行及考核</h5>
          <p>共包含3个条款，考核指标为承储企业的累计调入量和库存量；承储企业储备的6万吨化肥用于满足本市内农业生产需要。</p>
        </li>
        <li>
          <h5>第六章：资金管理</h5>
          <p>共包含2个条款，化肥淡季储备补助标准为60元/吨，总补助费用为360万元/年。</p>
        </li>
        <li>
          <h5>第七章：绩效管理和监督检查</h5>
          <p>共包含6个条款，建立事中事后监管机制，根据考核结果，市财政局按程序下达资金。</p>
        </li>
        <li>
          <h5>第八章：附则</h5>
          <p>共包含1个条款，明确《办法》施行时间。</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  // 组件逻辑
}
</script>

<style scoped>
.GanNews3 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: .3704rem;
  justify-content: center;
  align-items: center;
}

.img {
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.content {
  margin-top: 3.3333rem;
  margin-bottom: .9259rem;
}

h3{
  text-align: center;
  margin-bottom: .1852rem;
}

h1, h2 {
  margin-bottom: .1852rem;
  font-weight: bold;
}

p {
  margin: .1852rem 0;
}

ul {
  padding-left: .3704rem;
}

li {
  margin-bottom: .3704rem;
}
</style>
