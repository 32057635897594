<template>
  <div class="GanNews4">
    <div class="img"></div>
    <div class="content">
      <h3>关于《2024年南昌市供销合作社联合社社有企业公开招聘公告》的补充公告</h3>
      <h4>一、招聘要求</h4>
      <p>根据业务发展需要，南昌市供销合作社联合社社有企业拟向社会和高校应届毕业生招聘10名企业职工，不限户籍。具体各岗位要求及招聘公告详见文末二维码。</p>

      <h4>二、在线报名</h4>
      <p>本次招聘工作实行统一报名、统一考试、统一聘用的办法，按照报名、笔试、资格审查、面试、加试、考察、体检、聘用、培训等程序进行。</p>

      <h4>三、报名时间</h4>
      <p>2024年7月27日9：00—8月5日17：00。</p>
      
      <h4>四、在线报名</h4>
      <p>诚信报考，凡弄虚作假者，一经查实即取消资格。本次招聘报名采取网络报名方式，每位报考人员限报一个岗位。报名人员可登入江西省人才测评中心网
        （<a href="https://www.jxrcrsksta.com/">https://www.jxrcrsksta.com</a>）
        进入“2024年南昌市供销合作社联合社社有企业公开招聘公告”专题进入报名系统或直接点击报名系统链接
        （<a href="https://e.jxrcrsksta.com/exam?examId=156">https://e.jxrcrsksta.com/exam?examId=156</a>）
        ，据实填写报名。</p>

      <h4>五、咨询电话</h4>
      <p>网上报名咨询电话：0791-86660579 、0791-86398193。
          南昌市供销有限公司综合部 0791-88322187
          以上电话请于工作日工作时间上午9:00-12：00，下午13:30-17:00内拨打</p>

      <h4>六、笔试内容</h4>
      <p>笔试方式及内容：笔试科目为两门，采取闭卷方式进行。考试内容为：参照《事业单位公开招聘分类考试公共科目笔试考试大纲（2022年版）》综合管理类（A类）。</p>
      <div class="code">
      <div class="code1">
          <img src="@/assets/2022code.png" alt="">
          <p>事业单位公开招聘分类考试公共科目笔试考试大纲（2022年版）</p>
        </div>
        <div class="code2">
          <img src="@/assets/2024code.png" alt="">
          <p>2024年南昌市供销合作社联合社社有企业公开招聘公告</p>
        </div>
        <div class="bottom">
            <p>
              南昌市供销有限公司2024年7月25日
            </p>
          </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  // 组件逻辑
}
</script>

<style scoped>
.GanNews4 {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: .3704rem;
  align-items: center;
}

.img {
  position: absolute;
  top: .7963rem;
  width: 100%;
  height: 2.7778rem;
  background-image: url('@/assets/sbn4.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.content {
  margin-top: 3.3333rem;
  margin-bottom: .9259rem;
}

h3{
  text-align: center;
  margin-bottom: .1852rem;
  font-size: 20pt;
}

p {
  margin: .1852rem 0;
}

ul {
  padding-left: .3704rem;
}

li {
  margin-bottom: .3704rem;
}

.code{
  text-align: center;
}

.code img{
  width: 2.963rem;
  height: 2.963rem;
}

.bottom{
  text-align: right;
}
</style>
