<template>
  <div class="GanKnowLedge">
    <h3>产品信息</h3>
    <ul>
      <li v-for="item in urlesList" :key="item.id">
        <a href="#">
          <img :src="item.urles" alt="">
        </a><br>
        <span>{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import urles from '@/assets/sb3.jpg'
export default {
data(){
  return{
    urlesList:[
      {
        id:39,
        urles,
        name:'占位字符'
      },
      {
        id:40,
        urles,
        name:'占位字符'
      },
      {
        id:41,
        urles,
        name:'占位字符'
      },
      {
        id:42,
        urles,
        name:'占位字符'
      },
      {
        id:43,
        urles,
        name:'占位字符'
      },
      {
        id:44,
        urles,
        name:'占位字符'
      },
      {
        id:45,
        urles,
        name:'占位字符'
      },
      {
        id:46,
        urles,
        name:'占位字符'
      },
      {
        id:47,
        urles,
        name:'占位字符'
      },
      {
        id:48,
        urles,
        name:'占位字符'
      },
      {
        id:49,
        urles,
        name:'占位字符'
      },
      {
        id:50,
        urles,
        name:'占位字符'
      }
    ]
  }
}
}
</script>

<style scoped>
ul {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(3, 1fr);
  justify-items: center;
  align-items: center;
  gap: .3704rem;
  margin: .463rem 0;
}

li {
  margin: 0;
  list-style: none;
}

ul img {
  width: 3.7037rem;
  height: 4.0741rem;
}

h3{
  margin-top: .5556rem;
  border: .037rem solid black;
  height: .7407rem;
  line-height: .7407rem;
}
</style>